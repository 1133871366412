/*
  mailto form
*/
(function($) {
  var $form = $('#contactform');
  var generateAddress = function (tld, ctry, address) {
    var e1 = address;
    var e2 = Math.pow(2,6);
    var e3 = String.fromCharCode(e2);
    var e4 = tld;
    var e5 = ctry;
    var e6 = e1 + e3 + e4 + '.' + e5;
    return e6;
  };

  var buildForm = function () {
    var subject         = 'Message to netallied.de about "uberEngine for Unreal"';
    var message         = $form.find('#textareaMessage').val();
    var firstname       = $form.find('#inputFirstName').val();
    var lastname        = $form.find('#inputLastName').val();
    var email           = generateAddress ('netallied', 'de', 'info');
    var company         = $form.find('#textareaMessage').val();
    var interestedIn    = $form.find('#inputState').val();
    var invitationCode  = $form.find('#inputInvitationCode').val();
    var br              = '%0D%0A';
    var space           = '%20';
    var mT              = '';
    mT += email;
    mT += '?subject=' + encodeURI(subject);
    mT += '&body=';
    mT += encodeURI(message) + br + br;
    mT += 'Name:' + space + encodeURI(firstname) + space + encodeURI(lastname) + br;
    mT += 'Email:' + space + encodeURI(email) + br;
    mT += 'Company:' + space + encodeURI(company) + br;
    mT += 'Interested%20in:' + space + encodeURI(interestedIn) + br;
    mT += 'Invitation%20Code:' + space + encodeURI(invitationCode) + br;

    $('.mail').attr("href", 'mailto:' + mT);
    $('.mail')[0].click();
  };

  if ($form.length) {
    $form.on('submit', function (e) {
      return buildForm();
    });
  }

  function styleOptional() {
    var $formGroup = $('.form__optional');
    $formGroup.each (function (i) {
      var $label = $(this).find('label');
      var $input = $(this).find('input');
      //console.log('label und input: ' + $label +  $input);
      $input
      .focus(function () {
        $label.removeClass('blur').addClass('focus');
      })
      .blur(function () {
        $label.removeClass('focus').addClass('blur');
      });
    });
  };
  styleOptional();

})(jQuery);
